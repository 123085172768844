
import { generalStore } from '@/store';
import { useI18n } from 'vue-i18n';
import { api } from '@/services/Api';
import { onMounted, computed, ref } from 'vue';
import moment from 'moment';
import swal from 'sweetalert2';
import router from '@/router';
import Q from 'q';
import { OrdersNotifications, ProductAlert } from '@/models/Product';
import { ProductAlertType } from '@/models/Enums';
import CodeScanner from '@/components/Order/CodeScanner/CodeScanner.vue';
import { CalendarSlot } from '@/models/Calendar';

export default {
    components: { CodeScanner },
    async setup() {
        const { t, locale } = useI18n();
        const codeScanner = ref<InstanceType<typeof CodeScanner>>();
        const productsCount = computed<number>(() => generalStore.state.productsCounts);
        const greating = () => {
            const date = new Date();
            const hours = date.getHours();
            const greatingText = hours < 12 ? t('home.goodMorning') : hours < 19 ? t('home.goodDay') : t('home.goodEvening');
            return greatingText + ' ' + (generalStore.state.user?.fullName ? generalStore.state.user?.fullName : '');
        };
        const orderNotifications = computed<number>(() => generalStore.getters.getOrderNotificationsCount);
        const productsAlerts = ref<ProductAlert[]>([]);
        const productsAlertsRecieved = ref<boolean>(false);
        const alertsData = computed(() => {
            return productsAlerts.value?.map(alert => {
                switch (alert.alertType) {
                    case ProductAlertType.SlotNotFound: {
                        return { link: '/calendar/v3', productName: alert.productName, key: 'slotNotFound' };
                    }
                    case ProductAlertType.ProductPriceNotFound: {
                        return { link: `/product/${alert.sourceId}`, productName: alert.productName, key: 'priceListNotFound' };
                    }
                    case ProductAlertType.ImageNotFound: {
                        return { link: `/product/${alert.sourceId}`, productName: alert.productName, key: 'imageNotFound' };
                    }
                    case ProductAlertType.SlotLengthLess30: {
                        return { link: '/calendar/v3', productName: alert.productName, key: 'slotLengthLess30' };
                    }
                    case ProductAlertType.ProductPriceLengthLess30: {
                        return { link: `/product/${alert.sourceId}`, productName: alert.productName, key: 'priceListLengthLess30' };
                    }
                    case ProductAlertType.OnAir: {
                        return { link: alert.message, productName: alert.productName, key: 'onAir', notificationId: alert.sourceId };
                    }
                    default: {
                        return { link: '/', productName: alert.productName, key: 'default' };
                    }
                }
            });
        });

        async function GoCreateProductStepOne() {
            swal.showLoading();
            const apiResult = await api.CanCreateProduct();
            await Q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: apiResult.errorMessage });
                return;
            }
            if (apiResult.data) {
                swal.close();
                router.push({ name: 'createProductStepOne', params: { subStep: 1 } });
            } else {
                swal.fire({ icon: 'error', text: t('product.warning.limit-products') });
                return;
            }
        }

        async function showModalIfNoProducts() {
            if (productsCount.value === 0) {
                swal.showLoading();
                await Q.delay(400);
                const isUserHasProductAlert = await swal.fire({
                    imageUrl: '/congrats.png',
                    imageWidth: 80,
                    imageHeight: 80,
                    text: t('user.hasNoProducts'),
                    customClass: {
                        confirmButton: 'green-button popup-bookit-button my-1 px-4',
                        cancelButton: 'link-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showCancelButton: true,
                    confirmButtonText: t('navbar.createProduct'),
                    cancelButtonText: t('button.notNow')
                });
                if (isUserHasProductAlert.isConfirmed) {
                    GoCreateProductStepOne();
                } else return;
            }
        }

        async function markAsReaded(notificationId: string) {
            swal.showLoading();
            const response = await api.notificationMarkAsReaded(notificationId, false);
            swal.close();
            if (response.error || response.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
                return;
            }
            productsAlerts.value = productsAlerts.value.filter(x => x.sourceId !== notificationId);
        }

        onMounted(async () => {
            const apiOrderNotifications = await api.getOrdersNotifications();
            if (apiOrderNotifications.error || apiOrderNotifications.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: apiOrderNotifications.errorMessage });
                return;
            }
            generalStore.commit('setOrdersNotifications', apiOrderNotifications.data);
            await Q.delay(800);
            showModalIfNoProducts();
            if (productsCount.value > 0) {
                try {
                    const alerts = await api.getProductsAlerts();
                    if (alerts.error || alerts.errorMessage) {
                        swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: alerts.errorMessage });
                        return;
                    }
                    productsAlerts.value = alerts.data ?? [];
                } finally {
                    productsAlertsRecieved.value = true;
                }
            }
        });

        function openCodeScanner() {
            codeScanner.value?.open();
        }

        return {
            orderNotifications,
            greating,
            productsAlerts,
            alertsData,
            locale,
            markAsReaded,
            codeScanner,
            openCodeScanner,
            productsAlertsRecieved
        };
    }
};
