
import { defineComponent, PropType, ref, computed } from 'vue';
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import { OrderDetails } from '@/models/Order';
import { ArrivalConfirmation } from '@/models/Enums';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import { useI18n } from 'vue-i18n';
import _ from 'lodash';
import Q from 'q';
import useOrder from '@/modules/useOrder';

export default defineComponent({
    components: { CheckBoxInput },
    emits: ['confirmed'],
    props: {
        orders: {
            type: Array as PropType<OrderDetails[]>,
            default: () => []
        }
    },
    setup(props, { emit }) {
        const { removemillis } = useOrder();
        const { t } = useI18n();
        const isDesktop = ref(false);
        const appElement = document.getElementById('app');
        if (appElement) {
            const offsetWidth = appElement.offsetWidth;
            if (offsetWidth > 991) {
                isDesktop.value = true;
            }
        }
        const items = ref(
            props.orders?.map(x => {
                return { order: x, collapse: true, selected: props.orders?.length == 1 };
            })
        );

        const selectedItems = computed(() => items.value.filter(x => x.selected));

        async function showWarning(title: string) {
            const result = await swal.fire({
                icon: 'warning',
                title: title,
                showConfirmButton: true,
                showCancelButton: true,
                showCloseButton: true,
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                confirmButtonText: t('button.yes'),
                cancelButtonText: t('button.no')
            });
            return result.isConfirmed;
        }

        async function validate(value: ArrivalConfirmation) {
            for (const item of selectedItems.value) {
                if (item.order.arrivalConfirmationId == ArrivalConfirmation.Arrived) {
                    if (value == ArrivalConfirmation.Arrived) {
                        swal.fire({
                            title: t('order.arrivalConfirmation.error.order-already-arrived'),
                            customClass: {
                                popup: 'error-popup-container',
                                confirmButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                            },
                            buttonsStyling: false,
                            confirmButtonText: t('button.close')
                        });
                        return false;
                    } else {
                        return await showWarning(t('order.arrivalConfirmation.warning.from-arrived-to-other'));
                    }
                }
                if (item.order.arrivalConfirmationId == ArrivalConfirmation.NotArrived) {
                    return await showWarning(t('order.arrivalConfirmation.warning.from-not-arrived-to-other'));
                }
                if (item.order.arrivalConfirmationId == ArrivalConfirmation.ArrivedPartially) {
                    return await showWarning(t('order.arrivalConfirmation.warning.from-arrived-partially-to-other'));
                }
            }
            return true;
        }

        async function saveArrivalConfirmation(value: ArrivalConfirmation) {
            const valid = await validate(value);
            if (!valid) {
                return;
            }
            swal.showLoading();
            const delay = Q.delay(400);
            const response = await api.UpdateArrivalConfirmations({
                orderIds: selectedItems.value.map(x => x.order.id),
                arrivalConfirmation: value
            });
            if (response.errorMessage) {
                swal.fire({ icon: 'error', text: response.errorMessage });
                return;
            }

            await delay;
            if (items.value.length == 1) {
                emit('confirmed');
            } else {
                items.value.forEach(x => (x.selected = false));
            }
            await Q.delay(200);
            swal.fire({
                icon: 'success',
                title: t(`order.arrivalConfirmation.${_.camelCase(ArrivalConfirmation[value])}`),
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                confirmButtonText: t('button.close')
            });
        }

        async function saveNotArrived() {
            await saveArrivalConfirmation(ArrivalConfirmation.NotArrived);
        }

        async function saveArrivedPartially() {
            await saveArrivalConfirmation(ArrivalConfirmation.ArrivedPartially);
        }

        async function saveArrived() {
            await saveArrivalConfirmation(ArrivalConfirmation.Arrived);
        }

        return { items, selectedItems, saveNotArrived, saveArrivedPartially, saveArrived, isDesktop, removemillis };
    }
});
