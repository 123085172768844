
import { defineComponent, ref, computed } from 'vue';
import OverlayModal from '../../Calendar/OverlayModal.vue';
import SearchResult from './SearchResult.vue';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { OrderDetails } from '@/models/Order';
import StreamBarcodeReader from './StreamBarcodeReader.vue';
import RadioInput from '../../RadioInput.vue';
import _ from 'lodash';
import { Result } from '@zxing/library';
import q from 'q';
import { OrderSearchType } from '@/models/Enums';

export default defineComponent({
    components: { OverlayModal, SearchResult, StreamBarcodeReader, RadioInput },
    setup() {
        const isOpen = ref(false);
        const searchBy = ref(OrderSearchType.OrderNumber);
        const searched = ref(false);
        const searchInput = ref('');
        const searchedInput = ref('');
        const orders = ref<OrderDetails[]>([]);
        const inputSearchRef = ref<HTMLInputElement | null>(null);
        const loaded = ref(false);

        const scannerHeight = computed(() => {
            return !loaded.value || searched.value ? 'squize' : 'expand';
        });

        async function open() {
            isOpen.value = true;
            loaded.value = false;
            const el = document.getElementById('search-order');
            await q.delay(100);
            if (el) {
                el.focus();
            }
        }

        function back() {
            orders.value = [];
            searchedInput.value = '';
            searched.value = false;
            searchInput.value = '';
            isOpen.value = false;
        }

        async function rescan(e: any) {
            orders.value = [];
            searchedInput.value = '';
            searched.value = false;
            searchInput.value = '';
        }

        async function findOrders() {
            swal.showLoading();
            orders.value = [];
            const apiResult = await api.findOrderBy(searchInput.value, searchBy.value);
            searchedInput.value = searchInput.value;
            searched.value = true;
            orders.value = apiResult.data?.orders ?? [];
            swal.close();
        }

        async function onDecode(result: Result) {
            const text = result.getText();
            if (text) {
                swal.showLoading();
                orders.value = [];
                const apiResult = await api.findOrderByScan(text);
                searchInput.value = apiResult.data?.value ?? '';
                searchedInput.value = apiResult.data?.value ?? '';
                searchBy.value = apiResult.data?.searchBy ?? OrderSearchType.OrderNumber;
                searched.value = true;
                orders.value = apiResult.data?.orders ?? [];
                orders.value;
                swal.close();
            }
        }

        function onLoaded() {
            loaded.value = true;
        }

        return {
            isOpen,
            open,
            back,
            rescan,
            searchInput,
            searchedInput,
            orders,
            findOrders,
            searched,
            inputSearchRef,
            onDecode,
            onLoaded,
            scannerHeight,
            searchBy
        };
    }
});
