
import { ref, onMounted, onBeforeUnmount, defineComponent, watchEffect } from 'vue'
import { BrowserMultiFormatReader, Exception, Result } from "@zxing/library";

export default defineComponent({
    props: {
        scan: {
            type: Boolean,
            default: false
        }
    },
    setup: (props, { emit }) => {
        const isLoading = ref(true);
        const codeReader = ref(new BrowserMultiFormatReader());
        const isMediaStreamAPISupported = ref(navigator && navigator.mediaDevices && "enumerateDevices" in navigator.mediaDevices);
        const scanner = ref<HTMLVideoElement | null>(null);

        function stop() {
            codeReader.value.stopContinuousDecode();
        }

        function start() {
            codeReader.value.decodeFromVideoDevice(null, scanner.value, (result, err) => {
                if (result) {
                    emit("decode", result);
                    stop();
                }
            });
        }

        watchEffect(() => {
            if (props.scan) {
                start();
            }
            else {
                stop();
            }
        })

        onMounted(() => {
            if (!isMediaStreamAPISupported.value) {
                throw new Exception("Media Stream API is not supported");
            }

            if (scanner.value) {
                scanner.value.oncanplay = (event) => {
                    isLoading.value = false;
                    emit("loaded");
                };
            }
        });

        onBeforeUnmount(() => {
            codeReader.value.reset();
        });

        return { scanner, isLoading };
    }
});
